import { Trans, useLingui } from "@lingui/react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Dropdown from "react-bootstrap/Dropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import {
    surveyEmissionEvaluationsExportExcelUrl,
    surveyLinksExportExcelUrl,
    surveySubmissionsExportExcelUrl,
    useListSurveyStatusByIdsQuery,
} from "../../services/api";
import { cropLabel } from "../../utils/crops";
import { formatDateTime } from "../../utils/format";
import { IconTooltipButton } from "../elements/IconPopoverButton";
import AddEditSurveyModal from "./AddEditSurveyModal";

function _prepareDataRow(survey, status, i18n, setSelectedSurvey) {
    const farmCount = status?.assigned_farm_count;
    const completeCount = status?.valid_complete_farm_count;
    const progress = ((farmCount && completeCount / farmCount) || 0) * 100;

    return {
        surveyTitle: survey?.title,
        surveyId: survey?.id,
        surveyType: survey?.type,
        rawSurvey: survey,
        isDemo: survey?.classifier == "DEMO",
        latestSubmissionDate: status?.latest_submission_date,
        surveyYear: survey?.year,
        productName: survey?.crop?.name,
        product: survey?.crop,
        farmCount,
        completeCount,
        progress,
        detailHandler: () => setSelectedSurvey(assignment),
    };
}

const columnHelper = createColumnHelper();

export default function SurveyList({
    data,
    setSelectedSurvey,
    globalFilter,
    setGlobalFilter,
}) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const navigate = useNavigate();

    const surveyIds = (data || []).map((s) => s.id);
    const { data: statusData } = useListSurveyStatusByIdsQuery({
        ids: surveyIds,
    });

    const preparedData = useMemo(() => {
        if (!data) return [];

        return data.map((survey) => {
            const status = (statusData || []).find(
                (status) => status.id == survey.id
            );
            return _prepareDataRow(survey, status, i18n, setSelectedSurvey);
        });
    }, [data, statusData]);

    const [sorting, setSorting] = useState([]);

    const [selectedEditSurvey, setSelectedEditSurvey] = useState(null);

    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.surveyTitle, {
                id: "surveyTitle",
                cell: (info) => {
                    const row = info.row;
                    const isDemo = row.original?.isDemo;
                    const surveyId = row.original?.surveyId;
                    return (
                        <>
                            <Link to={`/survey/${surveyId}/assignments`}>
                                {info.getValue()}
                            </Link>{" "}
                            {isDemo && <Badge bg="warning">DEMO</Badge>}
                        </>
                    );
                },
                header: () => <Trans id="survey.table_header.title" />,
            }),
            columnHelper.accessor(
                (row) => {
                    return cropLabel(row.product, currentLanguage);
                },
                {
                    id: "product",
                    header: () => (
                        <Trans id="survey.table_header.product_name" />
                    ),
                }
            ),
            columnHelper.accessor((row) => row.surveyType, {
                id: "surveyType",
                cell: (info) => {
                    if (info.getValue() == "SUPPLIER") return "Product";
                    if (info.getValue() == "FARM") return "Crop";
                },
                header: () => <Trans id="survey.table_header.type" />,
            }),
            columnHelper.accessor((row) => row.surveyYear, {
                id: "year",
                header: () => <Trans id="survey.table_header.year" />,
            }),
            columnHelper.accessor((row) => row.progress, {
                id: "status",
                cell: (info) => {
                    const row = info.row;
                    const progress = info.getValue();
                    const farmCount = row.original?.farmCount;
                    const completeCount = row.original?.completeCount;

                    return (
                        <ProgressBar
                            className="survey-status-progress"
                            variant="primary"
                            now={progress}
                        />
                    );
                },
                header: () => <Trans id="survey.table_header.status" />,
            }),
            columnHelper.accessor((row) => row.progress, {
                id: "status_count",
                cell: (info) => {
                    const row = info.row;
                    const farmCount = row.original?.farmCount || 0;
                    const completeCount = row.original?.completeCount || 0;

                    return (
                        <span className="small">{`${completeCount} / ${farmCount}`}</span>
                    );
                },
                header: () => "",
            }),
            columnHelper.accessor((row) => row.latestSubmissionDate, {
                id: "submissionDate",
                cell: (info) => (
                    <>{info.getValue() && formatDateTime(info.getValue())}</>
                ),
                header: () => <Trans id="survey.table_header.last_activity" />,
            }),
            columnHelper.display({
                id: "actions",
                cell: (info) => {
                    const row = info.row;
                    const surveyId = row.original?.surveyId;
                    const survey = row.original?.rawSurvey;
                    const urlExportSubmissions =
                        surveySubmissionsExportExcelUrl({
                            surveyId,
                        });
                    const urlExportLinks = surveyLinksExportExcelUrl({
                        surveyId,
                    });
                    const urlExportEmissionEvaluations =
                        surveyEmissionEvaluationsExportExcelUrl({ surveyId });
                    const isNotFarmType = row.original?.surveyType != "FARM";

                    return (
                        <>
                            <ButtonToolbar className="justify-content-énd">
                                <ButtonGroup className="me-2">
                                    <IconTooltipButton
                                        infoText={"Open"}
                                        iconName={"list-ul"}
                                        buttonVariant={"primary"}
                                        buttonSize={"sm"}
                                        onClick={() =>
                                            navigate(
                                                `/survey/${surveyId}/assignments`
                                            )
                                        }
                                    ></IconTooltipButton>
                                </ButtonGroup>
                                {isNotFarmType && (
                                    <ButtonGroup className="me-2">
                                        <IconTooltipButton
                                            infoText={"Edit"}
                                            iconName={"pencil-fill"}
                                            buttonVariant={"light"}
                                            buttonSize={"sm"}
                                            onClick={() =>
                                                setSelectedEditSurvey(survey)
                                            }
                                        ></IconTooltipButton>
                                    </ButtonGroup>
                                )}
                                <ButtonGroup className="me-2">
                                    <Dropdown id={`more-${surveyId}`}>
                                        <Dropdown.Toggle
                                            variant="light"
                                            size="sm"
                                            id={`more-toggle-${surveyId}`}
                                        >
                                            More
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align={"end"}>
                                            <Dropdown.Item
                                                href={urlExportLinks}
                                                target="_blank"
                                            >
                                                <Trans id="survey.export_assignment_links_excel" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={urlExportSubmissions}
                                                target="_blank"
                                            >
                                                <Trans id="survey.export_submissions_excel" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={
                                                    urlExportEmissionEvaluations
                                                }
                                                target="_blank"
                                            >
                                                <Trans id="survey.export_emission_evaluations_excel" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </>
                    );
                },
            }),
        ],
        [currentLanguage]
    );

    const table = useReactTable({
        data: preparedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: "includesString",
        enableGlobalFilter: true,
        // debugTable: true,
        // debugRows: true,
    });

    return (
        <>
            <AddEditSurveyModal
                show={!!selectedEditSurvey}
                closeModal={() => setSelectedEditSurvey(null)}
                existingSurvey={selectedEditSurvey}
            />
            <Table className="survey-list">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className={
                                                header.column.getCanSort()
                                                    ? "cursor-pointer user-select-none"
                                                    : ""
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: " ▲",
                                                desc: " ▼",
                                            }[header.column.getIsSorted()] ??
                                                null}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="align-middle">
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}
