import { Trans, useLingui } from "@lingui/react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Table from "react-bootstrap/Table";
import { formatDateTime, formatNumber } from "../../utils/format";
import {
    ghgInventorySummary,
    ghgInventoryTotal,
} from "../../utils/ghgInventory";
import { assembleAssessmentLink } from "../../utils/survey";
import { openInNewTab } from "../../utils/ui";
import { IconTooltipButton } from "../elements/IconPopoverButton";

const statusMap = {
    pending: {
        color: "light",
        labelId: "survey_assignment.status_pending",
        icon: "slash-square-fill",
    },
    submitted: {
        color: "warning",
        labelId: "survey_assignment.status_submitted",
        icon: "exclamation-square-fill",
    },
    complete: {
        color: "primary",
        labelId: "survey_assignment.status_completed_evaluated",
        icon: "check-square-fill",
    },
};

function _prepareDataRow(assignment, survey, i18n, setSelectedAssignment) {
    const evaluation = assignment?.latest_evaluation;
    const isEvaluationActive = !survey?.evaluation_inactive;
    const isSubmitted = assignment?.latest_submission;
    const isSubmissionValidComplete =
        assignment?.latest_submission?.is_valid_complete;
    const isPrefilledSubmission =
        assignment?.latest_submission?.is_prefilled_data;
    const isCompleteAndEvaluated =
        isSubmissionValidComplete &&
        (!isEvaluationActive ||
            (evaluation?.results && evaluation?.results.length > 0));

    let status = "";
    let submissionDate = "";
    let submissionDateStr = "";
    if (!isSubmitted || isPrefilledSubmission) {
        status = "pending";
    } else {
        submissionDate = assignment?.latest_submission?.received_on;
        submissionDateStr = formatDateTime(submissionDate);
        if (isCompleteAndEvaluated) {
            status = "complete";
        } else {
            status = "submitted";
        }
    }

    const areaHa = evaluation?.meta_details?.area_ha;
    const yieldT = evaluation?.meta_details?.yield_net_kg / 1000;
    const ghgSummary = ghgInventorySummary(
        evaluation?.results || [],
        areaHa,
        yieldT,
        i18n
    );

    return {
        farmName: assignment?.farm?.name,
        status: status,
        farmClassifier: assignment?.farm?.classifier,
        submissionDate: submissionDate,
        submissionDateStr: submissionDateStr,
        isPrefilled: isPrefilledSubmission,
        areaHa: areaHa,
        yieldT: yieldT,
        totalKgCo2ePerTonne: ghgInventoryTotal(ghgSummary, "tonne"),
        totalKgCo2ePerHa: ghgInventoryTotal(ghgSummary, "hectare"),
        assignment: assignment,
        detailHandler: () => setSelectedAssignment(assignment),
    };
}

const columnHelper = createColumnHelper();

export default function AssignmentList({
    survey,
    data,
    setSelectedAssignment,
    globalFilter,
    setGlobalFilter,
    onCopyFarmLink,
}) {
    const { i18n } = useLingui();

    const preparedData = useMemo(() => {
        if (!data) return [];
        return data.map((assignment) =>
            _prepareDataRow(assignment, survey, i18n, setSelectedAssignment)
        );
    }, [data]);
    const dataAvailable = preparedData && preparedData.length > 0;

    const [sorting, setSorting] = useState([]);

    const columns = useMemo(() => {
        const isFarmSurvey = survey?.type == "FARM";
        const columnDefs = [
            columnHelper.accessor((row) => row.farmName, {
                id: "farmName",
                cell: (info) => {
                    const row = info.row;
                    const isDemo = row.original?.farmClassifier == "DEMO";
                    return (
                        <>
                            {info.getValue()}{" "}
                            {isDemo && <Badge bg="warning">DEMO</Badge>}
                        </>
                    );
                },
                header: () => <Trans id="survey.table_header.farm" />,
            }),
            isFarmSurvey &&
                columnHelper.accessor((row) => row.yieldT, {
                    id: "yieldT",
                    cell: (info) => (
                        <>
                            {(info.getValue() &&
                                formatNumber(info.getValue(), 0)) ||
                                ""}
                        </>
                    ),
                    header: () => (
                        <>
                            <Trans id="survey.table_header.yield" />
                            <br />
                            <span className="fw-normal">(t)</span>
                        </>
                    ),
                }),
            isFarmSurvey &&
                columnHelper.accessor((row) => row.areaHa, {
                    id: "areaHa",
                    cell: (info) => (
                        <>
                            {(info.getValue() &&
                                formatNumber(info.getValue(), 0)) ||
                                ""}
                        </>
                    ),
                    header: () => (
                        <>
                            <Trans id="survey.table_header.area" />
                            <br />
                            <span className="fw-normal">(ha)</span>
                        </>
                    ),
                }),
            isFarmSurvey &&
                columnHelper.accessor((row) => row.yieldT / row.areaHa, {
                    id: "productivityTHa",
                    cell: (info) => (
                        <>
                            {(info.getValue() &&
                                formatNumber(info.getValue(), 2)) ||
                                ""}
                        </>
                    ),
                    header: () => (
                        <>
                            <Trans id="survey.table_header.productivity" />
                            <br />
                            <span className="fw-normal">(t/ha)</span>
                        </>
                    ),
                }),
            isFarmSurvey &&
                columnHelper.accessor((row) => row.totalKgCo2ePerTonne, {
                    id: "totalKgCo2ePerTonne",
                    cell: (info) => (
                        <>
                            {(info.getValue() &&
                                formatNumber(info.getValue(), 2)) ||
                                ""}
                        </>
                    ),
                    header: () => (
                        <>
                            <Trans id="survey.table_header.per_tonne" />
                            <br />
                            <span className="fw-normal">
                                (kgCO<sub>2</sub>e/t)
                            </span>
                        </>
                    ),
                }),
            isFarmSurvey &&
                columnHelper.accessor((row) => row.totalKgCo2ePerHa, {
                    id: "totalKgCo2ePerHa",
                    cell: (info) => (
                        <>
                            {(info.getValue() &&
                                formatNumber(info.getValue(), 0)) ||
                                ""}
                        </>
                    ),
                    header: () => (
                        <>
                            <Trans id="survey.table_header.per_ha" />
                            <br />
                            <span className="fw-normal">
                                (kgCO<sub>2</sub>e/ha)
                            </span>
                        </>
                    ),
                }),
            isFarmSurvey &&
                columnHelper.accessor(
                    (row) => row.totalKgCo2ePerHa * row.areaHa,
                    {
                        id: "totalKgCo2e",
                        cell: (info) => (
                            <>
                                {(info.getValue() &&
                                    formatNumber(info.getValue(), 0)) ||
                                    ""}
                            </>
                        ),
                        header: () => (
                            <>
                                <Trans id="survey.table_header.total" />
                                <br />
                                <span className="fw-normal">
                                    (kgCO<sub>2</sub>e)
                                </span>
                            </>
                        ),
                    }
                ),
            columnHelper.accessor((row) => row.status, {
                id: "status",
                cell: (info) => {
                    const value = info.getValue();
                    const status = value in statusMap && statusMap[value];
                    const color = status?.color;
                    const labelId = status?.labelId;
                    const icon = status?.icon;
                    return (
                        <IconTooltipButton
                            iconOnly={true}
                            infoText={<Trans id={labelId} />}
                            iconName={icon}
                            iconClassName={`text-${color}`}
                        ></IconTooltipButton>
                    );
                },
                header: () => <Trans id="survey.table_header.status" />,
            }),
            columnHelper.accessor((row) => row.submissionDate, {
                id: "submissionDate",
                cell: (info) => (
                    <>{info.getValue() && formatDateTime(info.getValue())}</>
                ),
                header: () => <Trans id="survey.table_header.last_activity" />,
            }),
            columnHelper.display({
                id: "actions",
                cell: (info) => {
                    const row = info.row;
                    return (
                        <ButtonToolbar>
                            <ButtonGroup className="me-2">
                                <IconTooltipButton
                                    infoText={i18n._(
                                        "survey.single_results_button_info"
                                    )}
                                    iconName={"bar-chart-fill"}
                                    onClick={() =>
                                        row.original?.detailHandler()
                                    }
                                    buttonVariant={"outline-primary"}
                                    buttonSize={"sm"}
                                    buttonDisabled={
                                        row.original?.status != "complete"
                                    }
                                ></IconTooltipButton>
                                <IconTooltipButton
                                    infoText={i18n._(
                                        "survey.edit_submission_button_info"
                                    )}
                                    iconName={"pencil-fill"}
                                    buttonVariant={"primary"}
                                    buttonSize={"sm"}
                                    onClick={() =>
                                        openInNewTab(
                                            assembleAssessmentLink(
                                                row.original?.assignment
                                                    ?.private_assignment_uri
                                            )
                                        )
                                    }
                                ></IconTooltipButton>
                            </ButtonGroup>
                            <ButtonGroup>
                                <IconTooltipButton
                                    infoTitle={i18n._(
                                        "survey.copy_farm_link_info_title"
                                    )}
                                    infoText={i18n._(
                                        "survey.copy_farm_link_info_text"
                                    )}
                                    iconName={"link-45deg"}
                                    usePopover={true}
                                    buttonVariant={"warning"}
                                    buttonSize={"sm"}
                                    onClick={() =>
                                        onCopyFarmLink(row.original?.assignment)
                                    }
                                ></IconTooltipButton>
                            </ButtonGroup>
                        </ButtonToolbar>
                    );
                },
            }),
        ];
        return columnDefs.filter((c) => !!c);
    }, [survey?.type]);

    const table = useReactTable({
        data: preparedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: "includesString",
        enableGlobalFilter: true,
        // debugTable: true,
        // debugRows: true,
    });

    if (!dataAvailable) {
        return (
            <p className="mt-3 mb-5 text-center">
                <Trans id="survey.no_assignments_yet" />
            </p>
        );
    }

    return (
        <Table>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder ? null : (
                                    <div
                                        className={
                                            header.column.getCanSort()
                                                ? "cursor-pointer user-select-none"
                                                : ""
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: " ▲",
                                            desc: " ▼",
                                        }[header.column.getIsSorted()] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
